import React from 'react'
import Layout from '../components/layout'
import { OutboundLink } from "gatsby-plugin-google-analytics"
import './contact.module.scss'


const Contact = () => {
    return (
        <Layout>
            <h1>Contact</h1>
            <p>Honestly, the best way to grab my attention is through Twitter.</p>
            <OutboundLink href="https://www.twitter.com/jsonify" target="_blank" rel="noopener noreferrer"><button>@jsonify</button></OutboundLink>
            <p>Or you can send me an email if that's more your style</p>
            <OutboundLink href="mailto:contact@jasonrueckert.com" target="_blank" rel="noopener noreferrer"><button>Email</button></OutboundLink>
        </Layout>
    )
}

export default Contact
